.add-wrapper {
  position: relative;
  display: grid;
  padding-left: 1.5rem;
  /* padding-bottom: 100px; */
  margin-top: 32px;
  width: 70%;
}

.add-circle-wrapper {
  overflow: hidden;
  text-align: center;
}

.add-circle-content {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  left: calc(50% - 25px);
  border: 1px solid #e1e1e1;
  top: -12.5px;
  background-color: var(--color-bg);
  z-index: 10;
  cursor: pointer;
}

.add-circle-content img {
  width: 15px;
}

.add-circle-wrapper:before,
.add-circle-wrapper:after {
  background-color: #4a4e5c;
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}

.add-circle-wrapper:before {
  right: 0;
  margin-left: -50%;
}

.add-circle-wrapper:after {
  left: 0;
  margin-right: -50%;
}

#add-popover {
  font-family: "Montserrat", sans-serif;
}

#add-popover .popover-body {
  font-size: 12px !important;
  padding: 5px 1rem;
}

.device-add-success {
  position: relative;
  top: -4px;
  text-align: left;
  color: limegreen;
  left: -500px;
  transition: all 0.4s ease-in-out;
}

.device-add-success.in {
  left: 0;
}

.device-add-success-mobile {
  top: 25px;
}

.device-add-success-mobile.in {
  left: 16px;
}

@media only screen and (max-width: 1140px) {
  .add-wrapper {
    width: 80%;
  }
}

@media only screen and (max-width: 980px) {
  .add-wrapper {
    width: 96%;
  }
}

@media only screen and (max-width: 768px) {
  .add-wrapper {
    padding-bottom: 100px;
  }
  .add-wrapper-home.add-wrapper-mobile {
    padding-left: 0;
    width: 100%;
    position: fixed;
    bottom: 0;
  }
  .add-wrapper-home.add-wrapper-mobile .add-circle-content {
    width: 63px;
    height: 63px;
    left: auto;
    right: 24px;
    top: 0;
    background: #202b33;
    border: 2.52px solid #e1e1e1;
    box-shadow: 0px 4.095px 8.19px rgb(0 0 0 / 20%);
    z-index: 0;
  }
  .add-wrapper-home.add-wrapper-mobile .add-circle-content img {
    width: 26px;
  }
}
